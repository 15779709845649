const config = {
  apiUrl: process.env.REACT_APP_APIURL || "http://localhost:5000",
  contentUrl: `${
    process.env.PUBLIC_URL || "https://dev.celebratelifeco.com"
  }/content`,
  mainUrl: process.env.PUBLIC_URL || "http://localhost:3000",
  gtmId: process.env.REACT_APP_GTM_ID || "",
  gtmAuth: process.env.REACT_APP_GTM_AUTH || "",
  gtmPreview: process.env.REACT_APP_GTM_PREVIEW || "env-3",
  stripePublishableKey:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_test_51MNOWODMmvtsl5brQL89jqGPZucWVqlRJN7NO6th70yjgfUztj7RbA12ZAuk9rCeYXfxXGFngCUCcBpyedfTzR0W00RfwtwjLD",
};

export default config;
