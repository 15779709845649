import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormTextField from "src/components/HookFormTextField";
import ToggleDateField from "src/components/ToggleDateField";
import { TributeWizardStepper } from "./TributeWizardStepper";
import { getYearsBetween } from "src/utils/formatTime";

const {
  Container,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
} = require("@mui/material");

const currentDate = new Date();

const schema = Yup.object().shape({
  dateOfBirth: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Must be a date")
    .min(new Date(1000, 0, 1), "Birth cannot be before the year 1000")
    .max(currentDate, "Birth cannot be in the future"),
  dateOfDeath: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Must be a date")
    .min(Yup.ref("dateOfBirth"), "Must be after date of birth")
    .max(currentDate, "Death cannot be in the future"),
  ageOfDeath: Yup.number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Must be a number")
    .positive("Age of death must be a positive number")
    .integer("Age of death must be a whole number"),
});

export const Step3 = ({ tribute = {}, onNext, onBack }) => {
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...tribute,
    },
  });

  const {
    getValues,
    setValue,
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  const onDateChange = () => {
    const birth = getValues("dateOfBirth");
    const death = getValues("dateOfDeath");

    if (birth && death) {
      const age = Math.max(0, getYearsBetween(birth, death));
      setValue("ageOfDeath", age, { shouldValidate: true });
    } else {
      setValue("ageOfDeath", null, { shouldValidate: true });
    }
  };

  const handleCheckboxChange = (box) => {
    setValue(box, !getValues(box));
    const field = box === "isFullDateOfBirth" ? "dateOfBirth" : "dateOfDeath";
    setValue(field, null);
    setValue("ageOfDeath", null);
  };

  const [isFullBirthDate, setIsFullBirthDate] = useState(
    tribute.isFullDateOfBirth
  );
  const [isFullDeathDate, setIsFullDeathDate] = useState(
    tribute.isFullDateOfDeath
  );

  return (
    <Container>
      <Typography>
        Enter the exact birth and death dates or just the years for your loved
        one. If you need to double check the correct dates, you can edit them at
        a later time!
      </Typography>
      <form onSubmit={handleSubmit(onNext)} autoComplete="off">
        <Stack spacing={2} mt={2}>
          <FormControlLabel
            sx={{ mx: 0 }}
            control={
              <Checkbox
                checked={!isFullBirthDate}
                onChange={() => {
                  setIsFullBirthDate(!isFullBirthDate);
                  handleCheckboxChange("isFullDateOfBirth");
                }}
              />
            }
            label="I only know the year of birth"
          />
          <ToggleDateField
            name="dateOfBirth"
            label="Date of Birth"
            formMethods={formMethods}
            isFullDate={getValues("isFullDateOfBirth")}
            onChange={onDateChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!isFullDeathDate}
                onChange={() => {
                  setIsFullDeathDate(!isFullDeathDate);
                  handleCheckboxChange("isFullDateOfDeath");
                }}
              />
            }
            label="I only know the year of death"
          />
          <ToggleDateField
            name="dateOfDeath"
            label="Date of Death"
            formMethods={formMethods}
            isFullDate={getValues("isFullDateOfDeath")}
            onChange={onDateChange}
          />
          <HookFormTextField
            type="number"
            name="ageOfDeath"
            label="Age"
            fullWidth
            formMethods={formMethods}
          />
        </Stack>
        <TributeWizardStepper
          activeStep={2}
          nextType="submit"
          onBack={onBack}
          nextDisabled={!formState.isValid && formState.submitCount > 0}
          nextLoading={isSubmitting}
        />
      </form>
    </Container>
  );
};
