import { useDispatch } from "react-redux";
import { updateProfileInfo } from "src/slices/authSlice";

const channel = window["BroadcastChannel"] && new BroadcastChannel("general");

export function updateProfileNotify(userProfile) {
  if (channel) {
    channel.postMessage({
      type: "updateProfile",
      userProfile,
    });
  }
}

export default function WindowMessageHandler() {
  const dispatch = useDispatch();

  if (channel) {
    channel.addEventListener("message", ({ data }) => {
      switch (data.type) {
        case "updateProfile":
          dispatch(updateProfileInfo(data.userProfile));
          break;
        default:
          break;
      }
    });
  }

  return null;
}
