const monthMapping = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatUTCDateToText(date, fallBack = "unknown") {
  if (!date) {
    return fallBack;
  }
  const x = new Date(date);

  if (x instanceof Date && !isNaN(x)) {
    return `${
      monthMapping[x.getUTCMonth()]
    } ${x.getUTCDate()}, ${x.getUTCFullYear()}`;
  }

  return fallBack;
}

export function formatDateToYearMonth(date, fallBack = "") {
  if (!date) {
    return fallBack;
  }
  const x = new Date(date);

  if (x instanceof Date && !isNaN(x)) {
    console.log(date, x.getFullYear(), x.getMonth());
    return `${x.getFullYear()}-${x.getMonth() + 1}`;
  }

  return fallBack;
}

export function getYearsBetween(date1, date2) {
  if (!date1 || !date2) {
    return null;
  }

  const x = new Date(date1);
  const y = new Date(date2);

  if (!(x instanceof Date && !isNaN(x)) || !(y instanceof Date && !isNaN(y))) {
    return null;
  }

  let age = y.getFullYear() - x.getFullYear();
  const m = y.getMonth() - x.getMonth();

  if (m < 0 || (m === 0 && x.getDate() > y.getDate())) {
    age--;
  }

  return age;
}

export function formatDateShortUTC(date) {
  if (date) {
    const x = new Date(date);
    if (isNaN(x.getYear())) return null;
    return x.toISOString().slice(0, 10);
  }
}

export function formatDateYearUTC(date) {
  if (date) {
    const x = new Date(date);
    if (isNaN(x.getYear())) return null;
    return x.toISOString().slice(0, 4);
  }
}

export function formatDateShortLocal(date) {
  if (date) {
    const x = new Date(date);
    if (isNaN(x.getYear())) return null;
    return x.toISOString().slice(0, 10);
  }
}

export function formatDateShortLocalWithTime(date) {
  if (date) {
    const x = new Date(date);
    if (isNaN(x.getYear())) return null;
    return x.toLocaleString();
  }
}
