import HookFormTextField from "src/components/HookFormTextField";
import HookFormDateField from "src/components/HookFormDateField";

export default function ToggleDateField({
  label,
  name,
  isFullDate,
  formMethods,
  onChange,
}) {
  const { setValue, getValues } = formMethods;

  const handleTextChange = (e) => {
    const value = e.target.value;
    setValue(name, value ? value : null);
    onChange();
  };

  return isFullDate ? (
    <HookFormDateField
      fullWidth
      name={name}
      label={label}
      formMethods={formMethods}
      onChange={onChange}
    />
  ) : (
    <HookFormTextField
      name={name}
      label={label}
      fullWidth
      formMethods={formMethods}
      inputProps={{ maxLength: 4 }}
      value={getValues(name) ? getValues(name).substring(0, 4) : ""}
      onChange={handleTextChange}
    />
  );
}
