import { useRef, useEffect, useState } from "react";

// https://nevo-golan.dev/posts/use-is-in-viewport
export default function useIsInViewport(onlyOneTime) {
  const elementRef = useRef();
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isInViewport = !!entries[0]?.isIntersecting;

      if (onlyOneTime && isInViewport) {
        observer.disconnect();
      }

      setIsInViewport(isInViewport);
    });

    observer.observe(elementRef.current);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { elementRef, isInViewport };
}
