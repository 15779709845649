const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};
const setRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};
const removeRefreshToken = () => {
  localStorage.removeItem("refreshToken");
};
const TokenService = {
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
};
export default TokenService;
