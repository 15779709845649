import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Navigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../components/Iconify";
import { useDispatch, useSelector } from "react-redux";
// action
import { IsLoggedOn, registerAsync } from "../../slices/authSlice";
import { trackWizardRegistrationCompleted } from "src/utils/trackingTags";

// ----------------------------------------------------------------------

export default function RegisterForm({
  defaultEmail,
  emailReadOnly = false,
  disableSuccessRedirect = false,
  onSuccess,
  inviteToken,
  createObituaryDialog = false,
}) {
  const compressed = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const isLoggedOn = useSelector(IsLoggedOn);
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: defaultEmail || "",
      password: "",
      inviteToken,
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      await dispatch(
        registerAsync(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.inviteToken,
          onSuccess
        )
      );
      if (createObituaryDialog) {
        trackWizardRegistrationCompleted();
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  if (isLoggedOn && !disableSuccessRedirect) {
    return <Navigate to="/" />;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={compressed ? 1 : 3}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={compressed ? 1 : 2}
          >
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps("lastName")}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            inputProps={{ readOnly: emailReadOnly }}
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size={compressed ? "medium" : "large"}
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
