import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
// components
import Page from "../components/Page";
import Spinner from "src/components/Spinner";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 768,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12),
}));

// todo: we can probably move these into 'redirects.txt' and redirect with 301s
// and then remove this component altogether
const mappings = [
  { id: 1, path: "isaac-harris-davis-parke" },
  { id: 2, path: "elmer-henry-ward" },
  { id: 3, path: "hannah-dover-parke" },
  { id: 4, path: "ann-robson-dover" },
  { id: 5, path: "stuart-dickson" },
  { id: 6, path: "mary-jane-champlin-dickson" },
  { id: 7, path: "george-richards-jones" },
  { id: 8, path: "harriet-bruckshaw-jones" },
  { id: 9, path: "cyril-call" },
  { id: 10, path: "henry-pickering" }, //END OF PAGE 1
  { id: 11, path: "sally-tiffany-call" },
  { id: 12, path: "chester-call" },
  { id: 13, path: "mary-ann-lemon-pickering" },
  { id: 14, path: "sarah-maria-dickson-call" },
  { id: 15, path: "anson-call" },
  { id: 16, path: "mary-flint-call" },
  { id: 17, path: "catherine-pickering-jones" },
  { id: 18, path: "thomas-bruckshaw-jones" },
  { id: 19, path: "joseph-richardson-dover" },
  { id: 20, path: "robert-wright-baxter" },
  { id: 21, path: "jane-mcphail-love-baxter" },
  { id: 22, path: "francis-wilson-gunnel" },
  { id: 23, path: "eugene-charles-blackmun" },
  { id: 24, path: "jane-mcphail-baxter-gunnel" },
  { id: 25, path: "vivian-jane-blackmun" },
  { id: 26, path: "howard-loring-harlow" },
  { id: 27, path: "archibald-archie-baxter-gunnell" },
  { id: 28, path: "maude-elizabeth-dover-parke" },
  { id: 29, path: "columbia-flint-call-jones" },
  { id: 30, path: "albert-william-bill-jones" },
  { id: 31, path: "gerald-jerry-a-gunnell" },
  { id: 32, path: "sarah-maria-jones-gunnell" },
];

export default function Redirector(props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const simplePath = location.pathname.replace(/^\/|\/$/g, "").toLowerCase();
    const foundRedirect = mappings.find((x) => x.path === simplePath);
    navigate(foundRedirect ? `/obituaries/${foundRedirect.id}` : "/404", {
      replace: true,
    });
  }, [location, navigate]);

  return (
    <Page title="Redirecting">
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h3" paragraph>
          Redirecting...
        </Typography>
        <Spinner fixedPosition={true} />
      </ContentStyle>
    </Page>
  );
}
