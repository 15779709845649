import ImgWithFallback from "src/components/ImgWithFallback";
import palette from "../theme/palette";
import {
  Stack,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import Dropzone from "react-dropzone";
import { Icon } from "@iconify/react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { resolvePrimaryImageSrc } from "src/utils/imageLinks";

export default function EditPrimaryPhoto({
  namespace,
  objectId,
  buttonText = "Change",
  updatePrimaryImage,
  isPrimaryImageLoading,
  primaryImageFileNamePrefix,
  isProfileImage = false,
  imageResolveFunction = resolvePrimaryImageSrc,
  isHeroImage = false,
  isLogoImage = false,
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showModal, setShowModal] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [crop, setCrop] = useState({ px: null, pct: null });
  const [naturalDim, setNaturalDim] = useState({});

  const closeModal = () => {
    setShowModal(false);
    setImageSelected(null);
  };

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    setNaturalDim({ width, height });
    const aspectRation = height / width;
    const cropParams = {
      unit: "%",
      width: 90,
      aspect: aspectRation,
    };
    if (width < height) {
      cropParams.width = 90;
    } else {
      cropParams.height = 90;
    }

    // Set the initial crop view
    const crop = centerCrop(
      isHeroImage
        ? makeAspectCrop(cropParams, 3 / 2, width, height)
        : makeAspectCrop(cropParams, 1, width, height),
      width,
      height
    );

    // set initial/default crop
    setCrop({ pct: crop });
  };

  const getCroppedImg = () => {
    const image = new Image();
    image.src = imageSelected;
    const canvas = document.createElement("canvas");

    const pixelCrop = {
      width: naturalDim.width * crop.pct.width * 0.01,
      height: naturalDim.height * crop.pct.height * 0.01,
      x: naturalDim.width * crop.pct.x * 0.01,
      y: naturalDim.height * crop.pct.y * 0.01,
    };

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As a blob
    return new Promise((resolve, _) => {
      canvas.toBlob((file) => {
        file.name = "Cropped.jpg";
        resolve(file);
      }, "image/jpeg");
    });
  };

  return (
    <Fragment>
      <Stack direction="column" sx={{ alignItems: "center" }}>
        <ImgWithFallback
          src={imageResolveFunction(
            namespace,
            objectId,
            primaryImageFileNamePrefix,
            "md",
            "webp"
          )}
          fallbackSrc={imageResolveFunction(
            namespace,
            objectId,
            primaryImageFileNamePrefix,
            "md",
            "jpg"
          )}
          style={{
            float: "left",
            margin: "0 1em 1em 0",
            borderRadius: isLogoImage ? "50%" : "0",
          }}
          width="300"
        />
        {isProfileImage ? (
          <Button
            variant="contained"
            startIcon={<Icon icon="ph:user-circle-fill" />}
            onClick={() => setShowModal(true)}
          >
            {buttonText}
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setShowModal(true)}>
            {buttonText}
          </Button>
        )}
      </Stack>
      <Dialog
        fullScreen={fullScreen}
        open={showModal}
        scroll="paper"
        onClose={closeModal}
      >
        <DialogTitle>Set primary photo</DialogTitle>
        {!imageSelected && (
          <DialogContent>
            <Dropzone
              maxFiles={1}
              multiple={false}
              accept={{
                "image/*": [".png", ".jpeg", ".jpg"],
              }}
              onDrop={(acceptedFiles, errors) => {
                if (acceptedFiles.length === 1 && errors.length === 0) {
                  setImageSelected(URL.createObjectURL(acceptedFiles[0]));
                } else {
                  enqueueSnackbar("error parsing primary image file", {
                    variant: "error",
                  });
                  console.log("Dropzone errors", errors);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      border: "dashed",
                      borderRadius: 1,
                      borderColor: palette.icon.contrasting,
                      padding: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Box>
                      <Icon
                        icon="ri:upload-cloud-line"
                        style={{ fontSize: "5em" }}
                        color={palette.icon.contrasting}
                      ></Icon>
                    </Box>
                    <Typography>
                      Drag and drop a photo here, or click to select a file.
                    </Typography>
                  </Stack>
                </div>
              )}
            </Dropzone>
          </DialogContent>
        )}
        {imageSelected && (
          <DialogContent>
            <ReactCrop
              crop={crop.pct}
              onChange={(px, pct) => {
                setCrop({ px: px, pct: pct });
              }}
              aspect={isHeroImage ? 3 / 2 : 1}
              keepSelection={true}
              circularCrop={isLogoImage}
            >
              <img
                alt="To Crop"
                src={imageSelected}
                style={{
                  width: "auto",
                  maxWidth: "100%",
                  maxHeight: "calc(100vh - 100px)",
                  height: "auto",
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </DialogContent>
        )}
        <DialogActions>
          {!isPrimaryImageLoading && (
            <Button variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
          )}
          {imageSelected && (
            <LoadingButton
              loading={isPrimaryImageLoading}
              onClick={async () => {
                await updatePrimaryImage(
                  {
                    objectId,
                    file: await getCroppedImg(),
                  },
                  {
                    onSuccess: () => {
                      enqueueSnackbar("Saved Successfully", {
                        variant: "success",
                      });
                      closeModal();
                    },
                    onError: (error) => {
                      enqueueSnackbar(error, { variant: "error" });
                    },
                  }
                );
              }}
            >
              Save
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
