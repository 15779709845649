import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

const horizontalRatio = 586 / 445;
export default function Logo({
  disabledLink = false,
  size = 40,
  sx,
  horizontalLogo = false,
}) {
  const filename = horizontalLogo ? "logo-black-horizontal" : "logo192";
  const width = !horizontalLogo ? size : horizontalRatio * size;

  const logo = (
    <picture>
      <source srcSet={`/static/${filename}.webp`} type="image/webp" />
      <img
        src={`/static/${filename}.png`}
        alt="Celebrate Life Co."
        height={size}
        width={width}
      />
    </picture>
  );

  if (disabledLink) {
    return <Box sx={sx}>{logo}</Box>;
  }

  return (
    <Box sx={sx}>
      <RouterLink to="/">{logo}</RouterLink>
    </Box>
  );
}
