import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Button, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import Iconify from "../../components/Iconify";
import Logo from "../../components/Logo";
//
import AccountPopover from "./AccountPopover";
import { useSelector } from "react-redux";
import { IsLoggedOn } from "src/slices/authSlice";
import NavList from "./NavList";
import BannerCta from "../../features/cta/BannerCta";
import { useAppState } from "src/hooks/appState";
import LoginRequiredDialog from "src/features/login/LoginRequiredDialog";
import { useState } from "react";

// ----------------------------------------------------------------------

export const APPBAR_MOBILE = 58;
export const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("md")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 1),
  },
}));

PortalNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function PortalNavbar({ user, onOpenSidebar }) {
  const isLoggedOn = useSelector(IsLoggedOn);
  const [modalOpen, setModalOpen] = useState(false);
  const onCloseModal = () => setModalOpen(false);

  const { showBanner } = useAppState();

  return (
    <>
      <LoginRequiredDialog
        open={modalOpen}
        onClose={onCloseModal}
        children={null}
        initialScreenToShow="login"
        onAuthenticated={onCloseModal}
      />
      <RootStyle>
        {showBanner && <BannerCta />}
        <ToolbarStyle
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Box sx={{ flexShrink: 1, minWidth: 75 }}>
            <IconButton
              onClick={onOpenSidebar}
              sx={{
                mr: 1,
                color: "text.primary",
                display: { xs: "flex", md: "none" },
              }}
            >
              <Iconify icon="eva:menu-fill" />
            </IconButton>
            <Logo size={75} sx={{ display: { xs: "none", md: "unset" } }} />
          </Box>

          <Box sx={{ display: "flex", flexGrow: 1, alignText: "center" }}>
            <Logo
              size={APPBAR_MOBILE}
              horizontalLogo
              sx={{
                display: { xs: "unset", md: "none" },
                margin: "auto",
                textAlign: "center",
              }}
            />

            <NavList sx={{ display: { xs: "none", md: "flex" } }} />
          </Box>

          <Box
            sx={{
              flexShrink: 1,
              display: "flex",
              minWidth: "75px",
              justifyContent: "right",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              {/* <NotificationsPopover /> */}
              {isLoggedOn ? (
                <AccountPopover user={user} />
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setModalOpen(true)}
                  sx={{
                    fontFamily: "karla",
                    fontWeight: "400",
                    p: 0.25,
                  }}
                >
                  Sign in
                </Button>
              )}
            </Stack>
          </Box>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
}
