import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

export default function LoginForm({
  defaultEmail,
  emailReadOnly = false,
  tryLogin,
  forgotPasswordRedirectOverride,
}) {
  const compressed = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // local state for component
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: defaultEmail || "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      await tryLogin(values.email, values.password);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={compressed ? 1 : 3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            inputProps={{ readOnly: emailReadOnly }}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="right"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <div></div>
          {/* TODO: ad a "To" page with a form for sending password reset email */}
          {forgotPasswordRedirectOverride ? (
            <Link
              variant="subtitle2"
              underline="hover"
              onClick={() => forgotPasswordRedirectOverride()}
            >
              Forgot password?
            </Link>
          ) : (
            <Link
              component={RouterLink}
              variant="subtitle2"
              to="/forgotPassword"
              underline="hover"
            >
              Forgot password?
            </Link>
          )}
        </Stack>
        <LoadingButton
          fullWidth
          size={compressed ? "small" : "large"}
          type="submit"
          variant="contained"
          loading={formik.isSubmitting}
        >
          {`Login`}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
