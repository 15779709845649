import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";

export default function HookFormCheckbox({
  name,
  label,
  size,
  formMethods,
  onChange: onChangeHandler = () => {},
  deps = [],
  ...props
}) {
  const {
    control,
    trigger,
    formState: { errors },
  } = formMethods;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl error={errors[name] && Boolean(errors[name])}>
          <FormControlLabel
            control={
              <Checkbox
                id={name}
                name={name}
                size={size || "medium"}
                checked={value || false}
                onChange={(e) => {
                  const checked = e.target.checked;
                  deps.forEach((x) => trigger(x));
                  onChange(checked);
                  onChangeHandler(checked);
                }}
                {...props}
              />
            }
            label={label || name}
          />
        </FormControl>
      )}
    />
  );
}
