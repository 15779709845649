import EditPrimaryPhoto from "src/components/EditPrimaryPhoto";
import { TributeWizardStepper } from "./TributeWizardStepper";
import { useUpdateObituaryPrimaryImage } from "src/hooks/obituary";
import { trackUploadObituaryPhoto } from "src/utils/trackingTags";
const { Container, Typography } = require("@mui/material");

export const Step2 = ({ tributeId, tribute = {}, onNext, onBack }) => {
  const updateObituaryPrimaryImage = useUpdateObituaryPrimaryImage();

  const updatePrimaryImage = async ({ objectId: obituaryId, file }, events) => {
    await updateObituaryPrimaryImage.mutateAsync({ obituaryId, file }, events);
    trackUploadObituaryPhoto(obituaryId, true);
  };

  return (
    <Container>
      <Typography>
        Upload your loved one's primary cover photo! If you can't decide on the
        perfect photo now, you can update it later.
      </Typography>
      <EditPrimaryPhoto
        namespace="obituary"
        objectId={tributeId}
        buttonText="Edit"
        updatePrimaryImage={updatePrimaryImage}
        isPrimaryImageLoading={updateObituaryPrimaryImage.isLoading}
        primaryImageFileNamePrefix={tribute.primaryImageFileNamePrefix}
      />
      <TributeWizardStepper
        activeStep={1}
        nextType="button"
        nextDisabled={false}
        onNext={onNext}
        onBack={onBack}
      />
    </Container>
  );
};
