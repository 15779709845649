import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { formatDateShortUTC } from "src/utils/formatTime";
// ----------------------------------------------------------------------

export default function HookFormDateField({
  name,
  label,
  helperText,
  formMethods,
  onChange: onChangeHandler = () => {},
  ...props
}) {
  const {
    control,
    formState: { errors },
  } = formMethods;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          id={name}
          name={name}
          type="date"
          autoComplete="false"
          label={label || name}
          InputLabelProps={{ shrink: true }}
          helperText={errors[name]?.message || helperText || ""}
          error={errors[name] && Boolean(errors[name])}
          value={formatDateShortUTC(value) || ""}
          onChange={(value) => {
            onChange(value);
            onChangeHandler(value);
          }}
          {...props}
        />
      )}
    />
  );
}
