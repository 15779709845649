import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Iconify from "src/components/Iconify";
import config from "src/utils/config";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Stack,
  Typography,
  Container,
  IconButton,
  Link,
} from "@mui/material";
import { trackClickedShopNow } from "src/utils/trackingTags";

export const Step7 = ({ id, close }) => {
  const obituaryUrl = `${config.mainUrl}/obituaries/${id}`;
  const navigate = useNavigate();

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(obituaryUrl);
  };

  const goToPage = (page) => {
    close();
    navigate(page);
  };

  const handleFacebookShare = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      obituaryUrl
    )}`;
    window.open(facebookShareUrl, "_blank");
  };

  return (
    <Container>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
      >
        <Iconify icon="eva:close-outline" width={20} height={20} />
      </IconButton>
      <Stack spacing={1.5} mt={1}>
        <div>
          <Typography paddingBottom={1}>
            Share your tribute with a direct link or with Facebook.
          </Typography>
          <Stack direction="row" spacing={1}>
            <TextField
              value={obituaryUrl}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
            <IconButton
              variant="outlined"
              color="primary"
              onClick={handleCopyUrl}
            >
              <ContentCopyIcon />
            </IconButton>
            <IconButton
              variant="outlined"
              color="primary"
              onClick={handleFacebookShare}
            >
              <Iconify icon="mdi:facebook" />
            </IconButton>
          </Stack>
        </div>
        <Typography sx={{ paddingTop: 2 }}>
          Elevate your Tribute through our products, from weather proof plaques
          to picture frames.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            trackClickedShopNow();
            goToPage("/shop");
          }}
        >
          Shop Now
        </Button>
        <Typography sx={{ paddingTop: 2 }}>
          Access or edit Tributes you've created{" "}
          <strong>
            <Link
              onClick={() => goToPage("/account/uploads")}
              sx={{ cursor: "pointer" }}
            >
              here
            </Link>
          </strong>{" "}
          or anytime from your account.
        </Typography>
      </Stack>
    </Container>
  );
};
