import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { simpleGet, simplePost } from "../services/api_util";
import { UserId } from "src/slices/authSlice";
import api from "src/services/api";

const getAdminUsers = async () => {
  const { data } = await api.get(`/user/admins`);
  return data;
};

const getUserProfile = simpleGet((id) => `/user/${id}`);
const getUserAddresses = simpleGet((id) => `/user/${id}/address`);

const createPaySetupIntent = simplePost(
  ({ id }) => `/user/${id}/paySetupIntent`
);
const updateDefaultPayMethod = simplePost(
  ({ id }) => `/user/${id}/defaultPaymentMethod`
);
const updateUserProfile = simplePost(({ id }) => `/user/${id}`);
const updatePassword = simplePost(({ id }) => `/user/${id}/password`);
const updateEmail = simplePost(({ id }) => `/user/${id}/email`);
const addAddress = simplePost(({ id }) => `/user/${id}/address`);

const updatePrimaryImage = async ({ userId, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await api.postForm(`/user/${userId}/primaryImage`, formData);
  const result = {
    id: userId,
    primaryImageFileNamePrefix: data,
  };
  return result;
};

const getUserUploads = simpleGet(
  (id, page = 0, status, perPage = 6) =>
    `/user/${id}/obituary?status=${status}&page=${page}&perPage=${perPage}`
);

export const useAdminUsers = () => {
  return useQuery(["adminUsers"], getAdminUsers, {
    refetchOnWindowFocus: false,
  });
};

export const useUserProfile = () => {
  const userId = useSelector(UserId);

  return useQuery(
    ["userProfile"],
    () => (userId ? getUserProfile(userId) : {}),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export const useUserAddresses = (id) => {
  return useQuery(["userAddresses"], () => (id ? getUserAddresses(id) : {}), {
    refetchOnWindowFocus: false,
  });
};

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUserProfile, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userProfile"]);
    },
  });
};

export const useCreatePaySetupIntent = () => {
  return useMutation(createPaySetupIntent);
};

export const useUpdateDefaultPaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation(updateDefaultPayMethod, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userProfile"]);
    },
  });
};

export const useUpdateEmail = () => {
  const queryClient = useQueryClient();

  return useMutation(updateEmail, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userProfile"]);
    },
  });
};

export const useUpdatePassword = () => {
  return useMutation(updatePassword);
};

export const useAddAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(addAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries(["userAddresses"]);
    },
  });
};

export const useUserUploads = (id, page = 0, status = "Published", perPage) => {
  return useQuery(
    ["UserUploadList", { id, page, status, perPage }],
    () => {
      if (!id) {
        return;
      }
      return getUserUploads(id, page, status, perPage);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export const useUpdateUserPrimaryImage = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePrimaryImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userProfile"]);
    },
  });
};
