import { SnackbarProvider } from "notistack";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import Notifier from "./components/Notifier";
import AutoLogon from "./components/AutoLogon";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Initialize } from "./slices/appSlice";
import { Box } from "@mui/material";
import Footer from "./components/Footer";
import ErrorBoundary from "./components/ErrorBoundary";
import { MapProvider } from "react-map-gl";
import { useAppState } from "./hooks/appState";
import { WizardProvider } from "./features/CreateTributeWizard/WizardContext";
import CreateObituaryDialog from "./features/CreateTributeWizard/CreateObituaryDialog";
// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Initialize());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { showFooter } = useAppState();

  return (
    <ThemeProvider>
      <MapProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Notifier />
          <ScrollToTop />
          <AutoLogon>
            <Box
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ErrorBoundary>
                <WizardProvider>
                  <Router />
                  <CreateObituaryDialog />
                </WizardProvider>
              </ErrorBoundary>
              {showFooter && <Footer />}
            </Box>
          </AutoLogon>
        </SnackbarProvider>
      </MapProvider>
    </ThemeProvider>
  );
}
