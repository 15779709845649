import axiosInstance from "./api";
import TokenService from "./token.service";
import { refreshAccessToken } from "../slices/authSlice";
import parseError from "../utils/parseError";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const { accessToken, accessTokenLastRefresh } = store.getState().auth;
      if (accessToken) {
        config.headers["Authorization"] = "Bearer " + accessToken;
        config._accessTokenLastRefresh = accessTokenLastRefresh;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (!originalConfig.url.startsWith("/auth/") && err.response) {
        // Access Token was expired but is needed for this call
        // attempt to refresh
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            //first we try to get the cross-tab lock
            await navigator.locks.request("refresh_token", async (lock) => {
              // now check if token was refreshed between the time the call failed
              // and the acquisition of the cross-tab lock
              const { accessTokenLastRefresh } = store.getState().auth;
              if (
                !originalConfig.accessTokenLastRefresh ||
                !accessTokenLastRefresh ||
                originalConfig.accessTokenLastRefresh >= accessTokenLastRefresh
              ) {
                // try refreshing the token
                const rs = await axiosInstance.post("/auth/refresh", {
                  refreshToken: TokenService.getRefreshToken(),
                });
                const { accessToken, refreshToken } = rs.data;
                dispatch(refreshAccessToken(accessToken));
                TokenService.setRefreshToken(refreshToken);
              }
            });
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(parseError(_error));
          }
        }
      }
      return Promise.reject(parseError(err));
    }
  );
};
export default setup;
