import { useRef, useState, useMemo } from "react";
import ReactQuill from "react-quill";
import { Alert, Box, styled, Typography } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import palette from "../../theme/palette";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import Quill from "quill";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

const Fieldset = styled("fieldset")(({ theme }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  ":hover": {
    borderColor: "#212b36",
  },
}));

const calculateWordCount = (text = "") => {
  text.trim();
  return text.trim().length > 0 ? text.trim().split(/\s+/).length : 0;
};

export default function HookFormEditor({
  name,
  label,
  placeholder,
  formMethods,
  wordCountWarning = 0,
  imageHandler,
}) {
  // eslint-disable-next-line no-unused-vars
  //  const [field, _, helpers] = useField(formikName);
  const { setValue, getValues } = formMethods;
  const [focused, setFocused] = useState(false);
  const quill = useRef();
  const [wordCount, setWordCount] = useState(
    calculateWordCount(quill?.current?.editor.getText())
  );
  const showWordCountWarning =
    wordCountWarning > 1 && wordCountWarning < wordCount;

  const handleChange = (content, delta, source, editor) => {
    setValue(name, editor.getContents());
    setWordCount(calculateWordCount(editor.getText()));
  };

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "indent",
    "header",
    "align",
    "script",
  ];

  if (imageHandler != null) {
    // Only include the "image" item if imageHandler is not null
    formats.push("image");
    formats.push("link");
    formats.push("float");
    formats.push("width");
    formats.push("height");
  }

  const toolbar = [
    [{ header: [2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    ["blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
  ];

  if (imageHandler != null) {
    // Only include the "image" item if imageHandler is not null
    toolbar.push(["image"]);
    toolbar.push(["link"]);
    toolbar.push(["float"]);
  }

  const handleImageInsert = () => {
    return async () => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.addEventListener("change", async () => {
        const file = input.files[0];
        if (file) {
          const imageUrl = await imageHandler(file);
          const range = quill.current.getEditor().getSelection();
          quill.current.getEditor().insertEmbed(range.index, "image", imageUrl);
        }
      });
      input.click();
    };
  };

  const modules = useMemo(
    () => ({
      imageActions: {},
      imageFormats: {},
      toolbar: {
        container: toolbar ? toolbar : null,
        handlers: {
          image: imageHandler ? handleImageInsert() : null,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Fieldset
      sx={{
        border: focused
          ? `2px solid ${palette.primary.main} !important`
          : "1px solid #d8dde1",
      }}
    >
      <legend
        style={{
          marginLeft: focused ? "6px" : "7px",
          padding: "0 5px",
        }}
      >
        <Typography
          sx={{
            color: focused ? palette.primary.main : "#747f8a",
            fontSize: "0.75rem",
          }}
        >
          {label}
        </Typography>
      </legend>
      <Box
        sx={{
          margin: focused ? "0 -2px" : "0 -1px",
          marginTop: "-5px",
          marginBottom: focused ? "-2px" : "-1px",
          ".ql-editor": {
            minHeight: "100px",
            maxHeight: "50vh",
            overflowY: "none",
          },
        }}
      >
        <ReactQuill
          ref={quill}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          theme="snow"
          name={name}
          defaultValue={getValues(name)}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Box>
      {wordCountWarning > 0 && (
        <Box
          sx={{
            display: "flex",
            background: "rgba(0, 0, 0, .25)",
            p: 1,
            justifyContent: "center",
          }}
        >
          <Alert severity={showWordCountWarning ? "warning" : "info"}>
            {showWordCountWarning
              ? `${
                  (wordCount || 0) - wordCountWarning
                } words will not show publicly.`
              : `${wordCountWarning - (wordCount || 0)} words remaining.`}{" "}
            {`Current plan is limited to ${wordCountWarning} word life overview.`}
          </Alert>
        </Box>
      )}
    </Fieldset>
  );
}
