import api from "./api";

export const simpleGet = (interpolateRoute) => {
  return async (...fields) => {
    const { data } = await api.get(interpolateRoute(...fields));
    return data;
  };
};

export const simplePost = (interpolateRoute) => {
  return async (fields) => {
    const { data } = await api.post(interpolateRoute(fields), fields.formData);
    return data;
  };
};
