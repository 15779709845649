import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  webpSupport: true,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    updateWebpSupport: {
      reducer: (state, action) => {
        state.webpSupport = action.payload;
      },
    },
  },
});

const { updateWebpSupport } = appSlice.actions;

// thunk
export const Initialize = () => async (dispatch) => {
  const webPcheck = new Promise((resolve) => {
    const image = new Image();
    image.addEventListener("error", () => resolve(false));
    image.addEventListener("load", () => resolve(image.width === 1));
    image.src =
      "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
  }).catch(() => false);

  const browserSupportsWebP = await webPcheck;
  dispatch(updateWebpSupport(browserSupportsWebP));
};

// selectors
export const SupportsWebp = (state) => state.app.webpSupport || true;

export default appSlice.reducer;
