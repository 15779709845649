import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledDiv = styled.div`
  margin: auto;
  width: 100%;

  .line {
    fill: none;
    stroke: #72ad8b;
    stroke-width: 6;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-dasharray: 1020;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
    filter: url(#filter965);
    animation: line1 2.5s ease-in-out infinite;
  }
  @keyframes line1 {
    0% {
      stroke-dashoffset: 0;
      stroke-width: 6;
      filter: url(#filter965);
    }
    15% {
      stroke-width: 3;
    }
    40% {
      stroke-width: 1;
    }
    50% {
      stroke-dashoffset: 1020;
      stroke-width: 0.1;
      filter: url(#filter965);
    }
    51% {
      filter: unset;
      stroke-width: 3;
    }
    85% {
      stroke-width: 3;
    }
    100% {
      stroke-dashoffset: 2040;
      stroke-width: 6;
    }
  }
`;

export default function Spinner({
  size = 75,
  margin = "auto",
  text,
  fixedPosition = false,
}) {
  const style = fixedPosition
    ? {
        top: "calc(100vh/2 - 150px)",
        left: `calc(100vw/2 - ${size / 2}px)`,
        position: "absolute",
      }
    : {
        margin: margin,
      };
  return (
    <>
      <Box
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          ...style,
        }}
      >
        <StyledDiv>
          {/* <img src={spin} width={size} height={size} /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 185 185"
            id="svg8"
          >
            <defs id="defs2">
              <filter
                style={{ colorInterpolationFilters: "sRGB" }}
                id="filter965"
                x="-0.029148698"
                width="1.0582974"
                y="-0.0370332"
                height="1.065765"
              >
                <feGaussianBlur
                  stdDeviation="0.1960521"
                  id="feGaussianBlur967"
                />
              </filter>
            </defs>
            <g id="layer1">
              <path
                className="line"
                d="m 156.41125,180.80816 c -10.07044,-28.31375 -21.10018,-87.12955 -39.4335,-82.959507 -34.99499,13.023767 -13.22211,70.189827 19.81591,67.701377 9.93025,-0.74855 22.67096,-5.1796 21.79732,-29.58209 -1.7089,-56.203183 -66.32601,-69.60536 -74.95485,-26.73518 -2.30026,19.3168 11.54313,35.08311 6.52982,35.33898 -0.95625,0.43794 -3.40941,-0.60699 -3.95224,-0.95987 C 65.3054,134.99291 52.66129,118.18881 41.88756,107.27005 1.0572602,65.890121 27.893911,27.496985 117.53256,71.520457 154.50975,92.77202 183.66644,-38.468915 113.4394,46.287537 128.58601,33.473024 127.35535,5.9213634 106.58882,5.4953987 84.02145,7.2747848 88.82981,24.961406 88.21679,43.796407 86.93122,35.153729 86.47083,23.342953 76.38395,21.376307 c -9.78931,-2.61194 -19.54659,0.709879 -24.91123,8.09615 -2.66341,3.33968 -4.35301,7.89662 -5.29363,13.38979 -1.62572,35.828332 21.19075,58.531023 1.55695,86.877883 l -9.65311,12.76701"
                id="path40"
              />
            </g>
          </svg>
        </StyledDiv>
      </Box>
      {text && (
        <Typography
          variant="h4"
          width="100%"
          textAlign="center"
          color="primary"
          sx={{
            top: `calc(100vh/2 - 150px + ${size}px)`,
            position: "absolute",
          }}
        >
          {text}
        </Typography>
      )}
    </>
  );
}
