import { Box } from "@mui/system";
import useDelay from "src/hooks/useDelay";
import useIsInViewport from "src/hooks/useIsInViewport";
import viewportMapping from "src/utils/viewportMapping";

export default function FadeAndSlideIn({
  children,
  changeDirectionOn = "sm",
  directionCollapsed = "left",
  delayOnCollapsed = false,
  direction = "bottom",
  magnitude = "50%",
  transitionSpeed = "0.7s",
  delayMs = 0,
}) {
  const { elementRef, isInViewport } = useIsInViewport(true);

  const isInViewportDelayed = useDelay(isInViewport, delayMs);
  const collapsed = window.innerWidth < viewportMapping[changeDirectionOn];

  const directionToUse = collapsed ? directionCollapsed : direction;
  const isInViewportFinal =
    collapsed && !delayOnCollapsed ? isInViewport : isInViewportDelayed;

  return (
    <div>
      <Box
        ref={elementRef}
        sx={{
          position: "relative",
          [directionToUse]: isInViewportFinal
            ? "0"
            : collapsed
            ? "-50%"
            : "-" + magnitude,
          opacity: isInViewportFinal ? "1" : "0",
          transition: transitionSpeed,
        }}
      >
        {children}
      </Box>
    </div>
  );
}
