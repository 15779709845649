import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, MobileStepper } from "@mui/material";

export const TributeWizardStepper = ({
  activeStep,
  onBack,
  backDisabled,
  backLabel = "Back",
  onNext,
  nextDisabled,
  nextLoading = false,
  nextLabel = "Next",
  nextType = "button",
}) => (
  <MobileStepper
    sx={{ mt: 2 }}
    steps={5}
    activeStep={activeStep}
    variant="dots"
    position="static"
    nextButton={
      <LoadingButton
        size="large"
        disabled={nextDisabled}
        type={nextType}
        loading={nextLoading}
        onClick={onNext}
      >
        {nextLabel} <KeyboardArrowRight />
      </LoadingButton>
    }
    backButton={
      <Button size="large" disabled={backDisabled} onClick={onBack}>
        <KeyboardArrowLeft /> {backLabel}
      </Button>
    }
  ></MobileStepper>
);
