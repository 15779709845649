import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef, useEffect } from "react";
// @mui
import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";
import config from "src/utils/config";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Spinner from "./Spinner";
import { useAppState } from "src/hooks/appState";

// ----------------------------------------------------------------------

const Page = forwardRef(
  (
    {
      children = {},
      title = "",
      meta = {},
      breadcrumbs = [],
      loading = false,
      showFooter = true,
      ...other
    },
    ref
  ) => {
    const location = useLocation();
    const theme = useTheme();
    const { setShowFooter } = useAppState();
    const cleanPath = location.pathname.replace(/\/$/, "").toLowerCase();
    let queryString = "";
    const [searchParams] = useSearchParams();

    // right now only search param to alway be include is pageIndex
    const pageIndex = searchParams.get("pageIndex");
    if (pageIndex) {
      queryString = `?pageIndex=${pageIndex}`;
    }

    const canonicalUrl = `${config.mainUrl}${cleanPath}${queryString}`;

    const finalTitle = `${title} - Celebrate Life Co.`;
    const finalDesc = meta.description || title;
    const finalImageUrl =
      meta.imageUrl || `${config.mainUrl}/static/logo192.png`;

    useEffect(() => {
      setShowFooter(showFooter);
    }, [setShowFooter, showFooter]);

    return (
      <>
        <Helmet>
          <title>{finalTitle}</title>
          <meta name="title" content={finalTitle} />
          <meta name="description" content={finalDesc} />
          <link rel="canonical" href={`${canonicalUrl}`} />

          <meta property="og:title" content={finalTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:description" content={finalDesc} />
          <meta property="og:image" content={finalImageUrl} />

          <meta name="twitter:title" content={finalTitle} />
          <meta name="twitter:description" content={finalDesc} />
          <meta name="twitter:image" content={finalImageUrl} />
          <meta name="twitter:url" content={canonicalUrl} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Box ref={ref} {...other}>
          {breadcrumbs.length > 0 && (
            <Container>
              <Breadcrumbs
                aria-label="breadcrumb"
                sx={{ my: theme.spacing(1) }}
              >
                {breadcrumbs.map((x, i) => {
                  if (x.route && x.route.length > 0) {
                    return (
                      <Link
                        key={x.label || i}
                        component={RouterLink}
                        to={x.route}
                        state={x.state}
                        underline="hover"
                        color="primary"
                      >
                        {x.label}
                      </Link>
                    );
                  }
                  return (
                    <Typography key={x.label || i} variant="body">
                      {x.label}
                    </Typography>
                  );
                })}
              </Breadcrumbs>
            </Container>
          )}
          {loading ? <Spinner fixedPosition={true} /> : <>{children}</>}
        </Box>
      </>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.shape({
    description: PropTypes.string,
  }),
};

export default Page;
