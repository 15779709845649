import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormTextField from "src/components/HookFormTextField";
import { TributeWizardStepper } from "./TributeWizardStepper";
const { Container, Typography, Stack } = require("@mui/material");

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required("first name is required")
    .max(50, "first name cannot exceed 50 characters"),
  middleName: Yup.string(),
  lastName: Yup.string()
    .required("last name is required")
    .max(50, "last name cannot exceed 50 characters"),
  displayName: Yup.string().required("display name is required"),
});

export const Step1 = ({ tribute = {}, onNext }) => {
  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...tribute,
    },
  });

  const {
    getValues,
    setValue,
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  const onNameChange = () => {
    if (!tribute.displayName.trim()) {
      const firstName = getValues("firstName").trim();
      const middleName = getValues("middleName").trim();
      const lastName = getValues("lastName").trim();

      const fullName = [firstName, middleName, lastName]
        .filter(Boolean)
        .join(" ");

      setValue("displayName", fullName, { shouldValidate: true });
    }
  };

  return (
    <Container>
      <Typography>Who are you creating this tribute for?</Typography>
      <form onSubmit={handleSubmit(onNext)} autoComplete="off">
        <Stack spacing={2} mt={2}>
          <HookFormTextField
            name="firstName"
            label="First Name"
            fullWidth
            formMethods={formMethods}
            onChange={onNameChange}
          />
          <HookFormTextField
            name="middleName"
            label="Middle Name"
            fullWidth
            formMethods={formMethods}
            onChange={onNameChange}
          />
          <HookFormTextField
            name="lastName"
            label="Last Name"
            fullWidth
            formMethods={formMethods}
            onChange={onNameChange}
          />
          <HookFormTextField
            name="displayName"
            label="Display Name"
            fullWidth
            formMethods={formMethods}
          />
        </Stack>
        <TributeWizardStepper
          activeStep={0}
          backDisabled={true}
          nextType="submit"
          nextDisabled={!formState.isValid && formState.submitCount > 0}
          nextLoading={isSubmitting}
        />
      </form>
    </Container>
  );
};
