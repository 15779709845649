import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormTextField from "src/components/HookFormTextField";
import HookFormCheckbox from "src/components/HookFormCheckbox";
import { TributeWizardStepper } from "./TributeWizardStepper";
const { Container, Typography, Stack } = require("@mui/material");

const schema = Yup.object().shape({
  placeOfBirth: Yup.string(),
  placeOfDeath: Yup.string(),
  isVeteran: Yup.boolean(),
});

export const Step4 = ({ tribute = {}, onNext, onBack }) => {
  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...tribute,
    },
  });

  const {
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  return (
    <Container>
      <Typography>
        Add basic information about your loved one! Additional categories are
        available if you upgrade to a premium subscription.
      </Typography>
      <form onSubmit={handleSubmit(onNext)} autoComplete="off">
        <Stack spacing={2} mt={2}>
          <HookFormTextField
            name="metadata.occupation"
            label="Occupation"
            fullWidth
            formMethods={formMethods}
          />
          <HookFormTextField
            name="placeOfBirth"
            label="Place of Birth"
            fullWidth
            formMethods={formMethods}
          />
          <HookFormTextField
            name="placeOfDeath"
            label="Place of Death"
            fullWidth
            formMethods={formMethods}
          />
          <HookFormCheckbox
            name="isVeteran"
            label="Was your loved one a veteran?"
            size="large"
            formMethods={formMethods}
          />
        </Stack>
        <TributeWizardStepper
          activeStep={3}
          nextType="submit"
          onBack={onBack}
          nextDisabled={!formState.isValid && formState.submitCount > 0}
          nextLoading={isSubmitting}
        />
      </form>
    </Container>
  );
};
