import { useEffect, useState } from 'react';

export default function useDelay(upstreamValue, lengthMs) {
  const [value, setValue] = useState();

  useEffect(() => {
    const upstreamValueCached = upstreamValue;

    const timeout = setTimeout(() => {
      setValue(upstreamValueCached);
    }, lengthMs);

    return () => clearTimeout(timeout);
  }, [lengthMs, upstreamValue])

  return value;
}