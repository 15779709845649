import React from "react";

const ImgWithFallback = ({
  src,
  alt,
  fallbackSrc,
  mediaType = "image/webp",
  onClick,
  ...delegated
}) => {
  return (
    <picture onClick={onClick}>
      <source srcSet={src} type={mediaType} />
      <img
        src={fallbackSrc}
        alt={alt}
        {...delegated}
        onError={(e) => {
          e.target.onerror = null;
          for (var i = 0; i < e.target.parentElement.childElementCount; i++) {
            const child = e.target.parentElement.children[i];
            if (child.tagName === "SOURCE" && child.type === "image/webp") {
              e.target.parentElement.children[i].srcset =
                "/static/logo512.webp";
            } else {
              e.target.parentElement.children[i].srcset = "/static/logo512.png";
            }
          }
        }}
      />
    </picture>
  );
};

export default ImgWithFallback;
