import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import { UserId } from "src/slices/authSlice";
import { useUserUploads } from "src/hooks/account";
import { resolvePrimaryImageSrc } from "src/utils/imageLinks";
import { SupportsWebp } from "src/slices/appSlice";
import Spinner from "src/components/Spinner";
import { LoadingButton } from "@mui/lab";
import {
  useAddObituaryDraft,
  useDeleteObituaryDraft,
} from "src/hooks/obituary";

import CreateObituaryWizard from "src/features/CreateTributeWizard/CreateObituaryWizard";
import LoginRequiredDialog from "src/features/login/LoginRequiredDialog";
import { useWizardContext } from "./WizardContext";
import { trackWizardDraftExistsViewed } from "src/utils/trackingTags";

const emptyArray = [];

const DraftListWithRedirect = ({
  draftsQuery,
  setDraftsLoaded,
  selectionMade,
  openWizard,
}) => {
  const supportsWebp = useSelector(SupportsWebp);

  const drafts = draftsQuery.data?.entries || emptyArray;
  const addObitDraft = useAddObituaryDraft();
  const deleteDraft = useDeleteObituaryDraft();

  const [creating, setCreating] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const loading = draftsQuery.isFetching || draftsQuery.isLoading || creating;
  const shouldCreateAndRedirect =
    !loading && draftsQuery.isSuccess && drafts.length === 0;
  const shouldUseExistingDraft =
    !loading &&
    draftsQuery.isSuccess &&
    drafts.length === 1 &&
    drafts[0].displayName === "";

  const createObituaryAndRedirect = useCallback(() => {
    selectionMade();
    openWizard(null);
  }, [openWizard, selectionMade]);

  useEffect(() => {
    if (!loading && draftsQuery.data?.entries.length > 0) setDraftsLoaded(true);
  }, [draftsQuery.data?.entries.length, loading, setDraftsLoaded]);

  useEffect(() => {
    if (shouldCreateAndRedirect && !creating) {
      setCreating(true);
      createObituaryAndRedirect();
    }
  }, [createObituaryAndRedirect, creating, shouldCreateAndRedirect]);

  useEffect(() => {
    if (shouldUseExistingDraft) {
      selectionMade();
      openWizard(drafts[0].id);
    }
  }, [drafts, selectionMade, shouldUseExistingDraft, openWizard]);

  return draftsQuery.isLoading ||
    shouldCreateAndRedirect ||
    shouldUseExistingDraft ? (
    <Spinner />
  ) : (
    <List sx={{ width: "100%", maxWidth: 720, bgcolor: "background.paper" }}>
      {drafts.map((draft) => (
        <ListItem key={draft.id} disablePadding>
          <ListItemButton
            onClick={() => {
              selectionMade();
              openWizard(draft.id);
            }}
          >
            <ListItemAvatar>
              <Avatar
                src={resolvePrimaryImageSrc(
                  "obituary",
                  draft.id,
                  draft.primaryImageFileNamePrefix,
                  "md",
                  supportsWebp ? "webp" : "jpg"
                )}
              />
            </ListItemAvatar>
            <ListItemText
              primary={draft.displayName || "Draft (name not entered yet)"}
              secondary={draft.displayYears}
            />
            <LoadingButton
              variant="text"
              color="error"
              loading={deletingId === draft.id}
              startIcon={
                <Iconify
                  icon="eva:close-circle-outline"
                  height="1.5em"
                  sx={{
                    width: "150%",
                  }}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                setDeletingId(draft.id);
                deleteDraft.mutate(draft.id, {
                  onSettled: () => {
                    setDeletingId(null);
                  },
                });
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
      <ListItem disablePadding>
        <ListItemButton
          onClick={createObituaryAndRedirect}
          disabled={addObitDraft.isLoading}
        >
          <ListItemAvatar>
            <Avatar
              variant="square"
              sx={{ bgcolor: "inherit", color: "text.secondary" }}
            >
              <Iconify
                icon="eva:plus-circle-outline"
                height="unset"
                sx={{
                  width: "100%",
                }}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Create new" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

const CreateObituaryDialog = () => {
  const userId = useSelector(UserId);

  const {
    modalOpen,
    wizardOpen,
    wizardDraftId,
    setWizardDraftId,
    memorialData,
    closeModal,
    openWizard,
  } = useWizardContext();

  const draftsQuery = useUserUploads(userId, 0, "Draft", -1); //This is getting required data from the server
  const [draftsLoaded, setDraftsLoaded] = useState(false);

  useEffect(() => {
    if (modalOpen && !memorialData) {
      setDraftsLoaded(false);
    }
  }, [modalOpen, memorialData]);

  useEffect(() => {
    if (
      draftsQuery.isSuccess &&
      draftsQuery.data?.entries.length > 0 &&
      memorialData
    ) {
      const foundEntry = draftsQuery.data.entries.find((obituary) =>
        obituary.obituaryMemorials?.some(
          (memorial) =>
            memorial.createdFromLocationMemorialId ===
            memorialData.obituaryMemorial.createdFromLocationMemorialId
        )
      );
      foundEntry && setWizardDraftId(foundEntry?.id);
    }
  }, [draftsQuery.data, draftsQuery.isSuccess, memorialData, setWizardDraftId]);

  useEffect(() => {
    if (modalOpen && draftsQuery.isSuccess && draftsQuery.data?.entries.length)
      trackWizardDraftExistsViewed();
  }, [draftsQuery.isSuccess, draftsQuery.data?.entries.length, modalOpen]);

  return (
    <LoginRequiredDialog
      fullWidth
      open={modalOpen}
      onClose={closeModal}
      createObituaryDialog={true}
    >
      {!memorialData && (
        <Dialog fullWidth open={modalOpen} onClose={closeModal}>
          <DialogTitle>
            {draftsLoaded
              ? "Continue working on an existing draft?"
              : "Loading..."}
            <IconButton
              aria-label="close"
              onClick={() => {
                closeModal();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="eva:close-outline" width={20} height={20} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DraftListWithRedirect
              draftsQuery={draftsQuery}
              setDraftsLoaded={setDraftsLoaded}
              selectionMade={() => {
                closeModal();
                openWizard(wizardDraftId);
              }}
              openWizard={openWizard}
            />
          </DialogContent>
        </Dialog>
      )}
      {wizardOpen && (
        <Dialog fullWidth open={wizardOpen} onClose={closeModal}>
          <CreateObituaryWizard
            draftId={wizardDraftId}
            memorialData={memorialData}
            onClose={closeModal}
          />
        </Dialog>
      )}
    </LoginRequiredDialog>
  );
};

export default CreateObituaryDialog;
