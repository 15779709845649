import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import { IsLoggedOn, sendPasswordResetAsync } from "src/slices/authSlice";
import LoginForm from "../login/LoginForm";
import { loginAsync } from "../../slices/authSlice";
import { Link as RouterLink /*useNavigate*/ } from "react-router-dom";
import RegisterForm from "../register/RegisterForm";
import {
  trackWizardLoginViewed,
  trackWizardRegistrationViewed,
} from "src/utils/trackingTags";
import ForgotPasswordForm from "src/pages/forgotPassword/ForgotPasswordForm";

// if user is not logged on we will show a dialog that allows them to register or login
// once the user is authenticated the child components willl render
const LoginRequiredDialog = ({
  open,
  onClose,
  onAuthenticated,
  children,
  createObituaryDialog = false,
  initialScreenToShow = "register",
}) => {
  const dispatch = useDispatch();
  const isLoggedOn = useSelector(IsLoggedOn);
  const [screenToShow, setScreenToShow] = useState(initialScreenToShow);
  const handleSendEmail = async (email) => {
    await dispatch(sendPasswordResetAsync(email));
  };

  useEffect(() => {
    let hasTracked = false;

    if (open && createObituaryDialog && !hasTracked && !isLoggedOn) {
      if (screenToShow === "register") {
        trackWizardRegistrationViewed();
      } else if (screenToShow === "login") {
        trackWizardLoginViewed();
      }
      hasTracked = true;
    }
  }, [open, screenToShow, createObituaryDialog, isLoggedOn]);

  if (isLoggedOn) {
    return children;
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle sx={{ pr: 5 }}>
        {screenToShow === "login"
          ? "Welcome Back!"
          : screenToShow === "register"
          ? "Lets get started!"
          : ""}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="eva:close-outline" width={20} height={20} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack>
          {screenToShow === "login" ? (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Sign in with your details below.
              </Typography>
              <LoginForm
                compressed
                tryLogin={async (email, password) =>
                  dispatch(
                    loginAsync(email, password, undefined, onAuthenticated)
                  )
                }
                forgotPasswordRedirectOverride={() =>
                  setScreenToShow("forgotPassword")
                }
              />
              <Typography variant="caption" sx={{ mt: 3, textAlign: "center" }}>
                Don't have an account?{" "}
                <Link
                  variant="caption"
                  component={Button}
                  onClick={() => setScreenToShow("register")}
                  sx={{ verticalAlign: "unset" }}
                >
                  Create an account
                </Link>
              </Typography>
            </>
          ) : screenToShow === "register" ? (
            <>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary">
                Create your account where you can manage all your tributes in
                one place
              </Typography>
              <RegisterForm
                compressed
                disableSuccessRedirect
                onSuccess={onAuthenticated}
                createObituaryDialog={true}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mt: 3, textAlign: "center" }}
              >
                Already have an account?{" "}
                <Link
                  component="button"
                  variant="caption"
                  sx={{ verticalAlign: "unset" }}
                  onClick={() => setScreenToShow("login")}
                >
                  Login
                </Link>
              </Typography>

              <Typography
                variant="caption"
                align="center"
                sx={{ color: "text.secondary", mt: 2, mb: 1 }}
              >
                By registering, I agree to Celebrate Life Co's&nbsp;
                <br />
                <Link
                  onClick={onClose}
                  component={RouterLink}
                  to="/termsandconditions"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  onClick={onClose}
                  component={RouterLink}
                  to="/privacypolicy"
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
            </>
          ) : screenToShow === "forgotPassword" ? (
            <>
              <Typography variant="h4" gutterBottom>
                Forgot your password?
              </Typography>

              <Typography sx={{ color: "text.secondary", mb: 2 }}>
                Enter your email address below and we'll send you reset link.
              </Typography>

              <ForgotPasswordForm
                sendEmail={async (email) => await handleSendEmail(email)}
              />

              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                <Link
                  variant="subtitle2"
                  onClick={() => setScreenToShow("login")}
                >
                  Back to login
                </Link>
              </Typography>
            </>
          ) : (
            <Typography>How did you get here?</Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default LoginRequiredDialog;
