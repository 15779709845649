import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { NavLink } from "react-router-dom";
import Iconify from "./Iconify";

export default function Footer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        position: "flex-end",
        flex: 2,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
        overflow: "hidden",
        mt: 4,
      }}
    >
      <Grid
        container
        sx={{
          alignItems: "center",
          backgroundImage: "linear-gradient(to bottom right, #161C24, #161C24)",
          backgroundColor: "#161C24",
          padding: 3,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <Box>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              {/* <Iconify
                icon="eva:email-outline"
                color={theme.palette.icon.contrasting}
                sx={{ mr: 0.5, width: 40, height: 40 }}
              /> */}
              <Iconify
                icon="eva:phone-outline"
                color={theme.palette.icon.contrasting}
                sx={{
                  mr: 0.5,
                  width: 40,
                  height: 40,
                }}
              />
              <Stack direction="column">
                <Link href="tel:4253625848" sx={{ textDecoration: "none" }}>
                  <Typography
                    sx={{ marginLeft: 2 }}
                    variant="h4"
                    color={theme.palette.grey[200]}
                  >
                    425-362-5848
                  </Typography>
                </Link>
                <Typography
                  sx={{ marginLeft: 2 }}
                  variant="caption"
                  color={theme.palette.grey[500]}
                >
                  Mon. - Fri. 9am - 7pm (MST)
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <Box>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Iconify
                icon="eva:email-outline"
                color={theme.palette.icon.contrasting}
                sx={{
                  mr: 0.5,
                  width: 40,
                  height: 40,
                }}
              />
              <Stack direction="column">
                <Link
                  href="mailto:info@celebratelifeco.com"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{ marginLeft: 2 }}
                    variant="h4"
                    color={theme.palette.grey[200]}
                  >
                    info@celebratelifeco.com
                  </Typography>
                </Link>
                <Typography
                  sx={{ marginLeft: 2 }}
                  variant="caption"
                  color={theme.palette.grey[500]}
                >
                  General questions and partnership
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <Typography variant="body2">
            &copy; Copyright {new Date().getFullYear()} Celebrate Life Co.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <Typography variant="body2">
            <Link
              sx={{ textDecoration: "none", color: "inherit" }}
              component={NavLink}
              to="privacypolicy"
            >
              Privacy Policy
            </Link>
            <br />
            <Link
              sx={{ textDecoration: "none", color: "inherit" }}
              component={NavLink}
              to="termsandconditions"
            >
              Terms & Conditions
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
