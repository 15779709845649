import React, { createContext, useState, useContext, useEffect } from "react";
import { BANNER_HEIGHT } from "src/features/cta/BannerCta";
import { APPBAR_MOBILE, APPBAR_DESKTOP } from "src/layouts/portal/PortalNavbar";

const ADJUSTED_APPBAR_MOBILE = APPBAR_MOBILE + 6;

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  // right now this bit of context only has a single piece of state
  // if this is expanded to include non primitive objects we will want to
  // memoize the objects befor including them in the context provider
  // for funcitons we will need to useRef to prevent unecessary rerenders

  // Following example shows memoizing an object with a function that could be used as the value
  // within the context:
  // const [currentUser, setCurrentUser] = useState(null);
  // const login = useCallback((response) => {
  //   storeCredentials(response.credentials);
  //   setCurrentUser(response.user);
  // }, []);

  // const contextValue = useMemo(
  //   () => ({
  //     currentUser,
  //     login,
  //   }),
  //   [currentUser, login]
  // );

  const [showFooter, setShowFooter] = useState(true);
  const [appbarMobile, setAppbarMobile] = useState(ADJUSTED_APPBAR_MOBILE);
  const [appbarDesktop, setAppbarDesktop] = useState(APPBAR_DESKTOP);

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (showBanner) {
      setAppbarMobile(ADJUSTED_APPBAR_MOBILE + BANNER_HEIGHT);
      setAppbarDesktop(APPBAR_DESKTOP + BANNER_HEIGHT);
    } else {
      setAppbarMobile(ADJUSTED_APPBAR_MOBILE);
      setAppbarDesktop(APPBAR_DESKTOP);
    }
  }, [showBanner]);

  return (
    <AppStateContext.Provider
      value={{
        showFooter,
        setShowFooter,
        appbarMobile,
        appbarDesktop,
        showBanner,
        setShowBanner,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  const {
    showFooter,
    setShowFooter,
    appbarMobile,
    appbarDesktop,
    showBanner,
    setShowBanner,
  } = useContext(AppStateContext);
  return {
    showFooter,
    setShowFooter,
    appbarMobile,
    appbarDesktop,
    showBanner,
    setShowBanner,
  };
};
