export default function parseError(error) {
  // eventually we will also want/need to look at the errors array
  // and if it exists look at parsing that as well
  if (error.response && error.response.data) {
    return (
      error.response.data.detail ||
      error.response.data.message ||
      error.response.data.title ||
      error.response.data.toString()
    );
  }
  return error.toString();
}
