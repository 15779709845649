import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CreateObituaryButton from "src/components/CreateObituaryButton";
import FadeAndSlideIn from "src/components/FadeAndSlideIn";

export const BANNER_HEIGHT = 60;

const BannerCta = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > BANNER_HEIGHT) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const bannerStyle = {
    height: !isScrolled ? BANNER_HEIGHT : 0,
    transition: "all 0.3s ease",
    overflow: "hidden",
  };

  return (
    <div style={bannerStyle}>
      <Box style={{ padding: "4px", backgroundColor: "#719374" }}>
        <Box
          gap={5}
          sx={{
            height: `${BANNER_HEIGHT - 8}px`,
            border: "1px solid white",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center", //could be space-between - don't love it though
            alignItems: "center",
            padding: "4px 8px",
            backgroundColor: "transparent",
          }}
        >
          <FadeAndSlideIn
            direction="left"
            magnitude="200%"
            transitionSpeed="1.2s"
            delayMs={300}
          >
            <Typography
              sx={{
                fontSize: ".9em",
                fontWeight: "bold",
                color: "#ffffff",
              }}
            >
              Create a Free Tribute Today!
            </Typography>
          </FadeAndSlideIn>
          <CreateObituaryButton
            variant="contained"
            sx={{
              backgroundColor: "#99bea7",
              color: "#ffffff",
              fontWeight: "bold",
              //fontSize: "0.7em",
              //height: "35px",
            }}
            openWizardSource="banner_cta_get_started_button"
          >
            Get Started
          </CreateObituaryButton>
        </Box>
      </Box>
    </div>
  );
};

export default BannerCta;
