import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function AuthenticatedOutlet() {
  const { authenticated } = useSelector((state) => state.auth);
  const location = useLocation();

  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}
