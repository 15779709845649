import { useForm } from "react-hook-form";
import { TributeWizardStepper } from "./TributeWizardStepper";
import HookFormEditor from "src/components/editor/HookFormEditor";
const { Container, Typography } = require("@mui/material");

export const Step5 = ({ tribute = {}, allowedWordCount, onNext, onBack }) => {
  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...tribute,
    },
  });

  const {
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  return (
    <Container>
      <Typography mb={2}>
        Enter a brief life story. This feature will be shown with your loved
        one's tribute. Upgrade to premium for unlimited text length.
      </Typography>
      <form onSubmit={handleSubmit(onNext)} autoComplete="off">
        <HookFormEditor
          name="contentAsQuill"
          label="Life History"
          placeholder="Tell us about this person's life and legacy..."
          wordCountWarning={allowedWordCount}
          formMethods={formMethods}
        />
        <TributeWizardStepper
          activeStep={4}
          nextType="submit"
          onBack={onBack}
          nextDisabled={!formState.isValid && formState.submitCount > 0}
          nextLoading={isSubmitting}
          nextLabel="Finish"
        />
      </form>
    </Container>
  );
};
