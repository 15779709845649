import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";

// API methods - not exposed outside this class
const getAll = async (
  page = 0,
  mustHavePrimaryPhoto = false,
  mustBeInPublicLists = true
) => {
  const { data } = await api.get(
    `/obituary?page=${page}&mustHavePrimaryPhoto=${mustHavePrimaryPhoto}&mustBeInPublicLists=${mustBeInPublicLists}`
  );
  return data;
};

const getDrafts = async (page = 0) => {
  const { data } = await api.get(`/obituary/drafts?page=${page}`);
  return data;
};

const get = async (id, isLoggedOn = false, checkCanEdit = false) => {
  const { data } = await api.get(
    `/obituary/${id}${!isLoggedOn ? "/public" : ""}${
      checkCanEdit ? "?checkCanEdit=true" : ""
    }`
  );
  return data;
};

const getMetadataTypes = async () => {
  const { data } = await api.get(`/obituary/metaDataType`);
  // always return as sorted list
  return (data || []).sort((a, b) => a.sortOrder - b.sortOrder);
};

const addObituaryDraft = async (newObituary) => {
  const { data } = await api.post(`/obituary`, newObituary);
  return data;
};

const deleteObituaryDraft = async (id) => {
  const { data } = await api.delete(`/obituary/${id}`);
  return data;
};

const updateObituaryStatus = async ({ id, status }) => {
  const { data } = await api.post(`/obituary/${id}/status`, { status });
  return data;
};

const updateObituaryIsHidden = async ({ id, isHidden }) => {
  const { data } = await api.post(`/obituary/${id}/hidden`, { isHidden });
  return data;
};

const update = async ({ obituaryId, obituary }) => {
  const { data } = await api.put(`/obituary/${obituaryId}`, obituary);
  data.id = obituaryId;
  return data;
};

const updateProduct = async ({ obituaryId, productId }) => {
  const { data } = await api.post(`/obituary/${obituaryId}/product`, {
    productId,
  });
  data.id = obituaryId;
  return data;
};

const completeSubscriptionPayment = async ({ obituaryId, paymentIntentId }) => {
  await api.post(`/obituary/${obituaryId}/payment`, {
    paymentIntentId,
  });

  return { id: obituaryId };
};

const updatePrimaryImage = async ({ obituaryId, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await api.postForm(
    `/obituary/${obituaryId}/primaryImage`,
    formData
  );
  const result = {
    id: obituaryId,
    primaryImageFileNamePrefix: data,
  };
  return result;
};

const addGalleryImage = async ({ obituaryId, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await api.postForm(
    `/obituary/${obituaryId}/galleryImage`,
    formData
  );
  const result = {
    id: obituaryId,
    imageGuid: data,
  };
  return result;
};

const deleteGalleryImage = async ({ obituaryId, obituaryImageId }) => {
  await api.delete(`/obituary/${obituaryId}/galleryImage/${obituaryImageId}`);
  return;
};

// Custom hooks
export const useObituary = (
  obituaryId,
  isLoggedOn,
  checkCanEdit = false,
  enabled = true
) => {
  return useQuery(
    ["Obituary", { obituaryId: parseInt(obituaryId), checkCanEdit }],
    () => get(obituaryId, isLoggedOn, checkCanEdit),
    {
      enabled: enabled,
      retry: !checkCanEdit,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useObituaries = (
  page = 0,
  mustHavePrimaryPhoto = false,
  mustBeInPublicLists = true
) => {
  return useQuery(
    [
      "ObitList",
      {
        page: page,
        mustHavePrimaryPhoto: mustHavePrimaryPhoto,
        mustBeInPublicLists: mustBeInPublicLists,
      },
    ],
    () => {
      return getAll(page, mustHavePrimaryPhoto, mustBeInPublicLists);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export const useDraftObituaries = (page = 0) => {
  return useQuery(
    ["DraftObitList", { page: page }],
    () => {
      return getDrafts(page);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export const useObituaryMetaDataTypes = () => {
  return useQuery(["ObitMetaDataTypes"], getMetadataTypes, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useAddObituaryDraft = () => {
  const queryClient = useQueryClient();

  return useMutation(addObituaryDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["UserUploadList"]);
    },
  });
};

export const useDeleteObituaryDraft = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteObituaryDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["UserUploadList"]);
    },
  });
};

//note that this only runs in the wizard in conjunction with useUpdateObituary, which has other invalidations
export const useUpdateObituaryStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(updateObituaryStatus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["UserUploadList"]);
      queryClient.invalidateQueries(["ObitList"]);
      queryClient.invalidateQueries(["partnerLocationMemorials"]);
      queryClient.invalidateQueries(["partnerLocationMemorial"]);
    },
  });
};

export const useUpdateObituaryIsHidden = () => {
  const queryClient = useQueryClient();

  return useMutation(updateObituaryIsHidden, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["ObitList"]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Obituary" &&
            query.queryKey[1].obituaryId === parseInt(data.id)
          );
        },
      });
    },
  });
};

export const useUpdateObituary = () => {
  const queryClient = useQueryClient();

  return useMutation(update, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Obituary" &&
            query.queryKey[1].obituaryId === parseInt(data.id)
          );
        },
      });
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["ObitList"]);
    },
  });
};

export const useUpdateObituaryProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(updateProduct, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["ObitList"]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Obituary" &&
            query.queryKey[1].obituaryId === parseInt(data.id)
          );
        },
      });
    },
  });
};

export const useUpdateObituaryPrimaryImage = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePrimaryImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["ObitList"]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Obituary" &&
            query.queryKey[1].obituaryId === parseInt(data.id)
          );
        },
      });
    },
  });
};

export const useAddObituaryGalleryImage = () => {
  const queryClient = useQueryClient();

  return useMutation(addGalleryImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Obituary" &&
            query.queryKey[1].obituaryId === parseInt(data.id)
          );
        },
      });
    },
  });
};

export const useDeleteObituaryGalleryImage = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteGalleryImage, {
    onSuccess: (data, { obituaryId }) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Obituary" &&
            query.queryKey[1].obituaryId === parseInt(obituaryId)
          );
        },
      });
    },
  });
};

export const useCompleteSubscriptionPayment = () => {
  const queryClient = useQueryClient();
  return useMutation(completeSubscriptionPayment, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["DraftObitList"]);
      queryClient.invalidateQueries(["ObitList"]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Obituary" &&
            query.queryKey[1].obituaryId === parseInt(data.id)
          );
        },
      });
    },
  });
};
