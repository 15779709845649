import { createSlice } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "./snackbarSlice";
import authService from "../services/auth.service";
import {
  trackLogin,
  trackSignUp,
  updateUserIdInDataLayer,
} from "src/utils/trackingTags";

const initialState = {
  user: null,
  accessToken: null,
  accessTokenLastRefresh: null,
  authenticated: false,
  pendingEmailVerification: false,
  loading: false,
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState,
    beginLoading: (state) => {
      state.loading = true;
    },
    login: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.acessTokenLastRefresh = Date.now();
      state.authenticated = action.payload.authenticated;
      state.pendingEmailVerification = action.payload.pendingEmailVerification;
      state.loading = false;
      updateUserIdInDataLayer(action.payload.user.id);
    },
    updateProfileInfo: (state, action) => {
      const profile = action.payload;
      const propsToTransfer = ["email", "firstName", "lastName", "userName"];

      for (let prop of propsToTransfer) {
        state.user[prop] = profile[prop];
      }
    },
    refreshAccessToken: (state, action) => {
      state.accessToken = action.payload;
      state.accessTokenLastRefresh = Date.now();
    },
    endLoading: (state) => {
      state.loading = false;
    },
  },
});

// Internal slice actions
const { logout, beginLoading, endLoading } = authSlice.actions;

// exported slice actions
export const { login, refreshAccessToken, updateProfileInfo } =
  authSlice.actions;

// following are exported async thunks
export const loginAsync =
  (email, password, inviteToken, onSuccess) => async (dispatch) => {
    dispatch(beginLoading());

    try {
      const result = await authService.login(email, password, inviteToken);
      dispatch(login(result));
      trackLogin(result.user.id);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(logout());

      const message = error;
      dispatch(
        enqueueSnackbar({
          message: `${message}`,
          options: { variant: "error" },
        })
      );
    }
  };

export const registerAsync =
  (email, firstName, lastName, password, inviteToken, onSuccess) =>
  async (dispatch) => {
    dispatch(beginLoading());

    try {
      const result = await authService.register(
        email,
        firstName,
        lastName,
        password,
        inviteToken
      );

      trackSignUp(result.user.id);

      dispatch(login(result));
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(logout());

      const message = error;
      dispatch(
        enqueueSnackbar({
          message: `${message}`,
          options: { variant: "error" },
        })
      );
    }
  };

export const logoutAsync = () => async (dispatch) => {
  //eventually I need to call masterdataservice and revoke my refresh token
  authService.logout();
  dispatch(logout());
};

export const resendVerificationAsync = (email) => async (dispatch) => {
  dispatch(beginLoading());
  try {
    await authService.resendVerification(email);
    dispatch(
      enqueueSnackbar({
        message: `Resent verification message to ${email}`,
        options: { variant: "success" },
      })
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: `unexpected error ${error}`,
        options: { variant: "error" },
      })
    );
  }
  dispatch(endLoading());
};

export const sendPasswordResetAsync = (email) => async (dispatch) => {
  dispatch(beginLoading());
  try {
    await authService.sendPasswordReset(email);
    dispatch(
      enqueueSnackbar({
        message: `If email found, reset link will be sent to ${email}`,
        options: { variant: "success" },
      })
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: `unexpected error ${error}`,
        options: { variant: "error" },
      })
    );
  }
  dispatch(endLoading());
};

export const refreshAsync = () => async (dispatch) => {
  dispatch(beginLoading());
  try {
    const result = await authService.tryRefresh();
    if (result) {
      dispatch(login(result));
    } else {
      dispatch(logout());
    }
  } catch (error) {
    dispatch(logout());
    console.log(error);
    dispatch(
      enqueueSnackbar({
        message: `Session has expired, please log back on.`,
        options: { variant: "warning" },
      })
    );
  }
};

// following are exported async thunks
export const PasswordResetWithTokenAsync =
  (email, token, password) => async (dispatch) => {
    dispatch(beginLoading());

    try {
      const result = await authService.passwordResetWithToken(
        email,
        token,
        password
      );
      dispatch(login(result));
    } catch (error) {
      dispatch(logout());

      const message = error;
      dispatch(
        enqueueSnackbar({
          message: `${message}`,
          options: { variant: "error" },
        })
      );
    }
  };

// exported selectors
export const IsAdmin = (state) =>
  state.auth.user?.roles?.includes("Admin") || false;

export const IsPartnerUser = (state) => {
  const validRoles = ["Admin", "Partner Admin", "Partner User"];
  return state.auth.user?.roles?.some((x) => validRoles.includes(x)) || false;
};

export const IsLoggedOn = (state) => state.auth.authenticated || false;

export const Roles = (state) => state.auth.user?.roles || [];

export const UserId = (state) => state.auth.user?.id || null;

export const Email = (state) => state.auth.user?.email || null;

export const IsPartnerAdmin = (partnerId) => (state) => {
  if (IsAdmin(state)) {
    return true;
  }

  return (
    state.auth.user?.partnerAdminAccess.includes(Number(partnerId)) || null
  );
};

export default authSlice.reducer;
